export const PERMANENT_FEATURES = Object.freeze({
	analyticsEventLogger: 'analyticsEventLogger',
	brazeSDK: 'brazeSDK',
	cypressMirageDebugging: 'cypressMirageDebugging',
	datadogRum: 'datadogRum',
	forcePromoBanner: 'forcePromoBanner',
	hotjar: 'hotjar',
	mirageServer: 'mirageServer',
	mirageServerSoftLaunch: 'mirageServerSoftLaunch',
	reactQueryDevtools: 'reactQueryDevtools',
	saleTimeController: 'saleTimeController',
	searchService: 'searchService',

	snowplowMiniRedirect: 'snowplowMiniRedirect',
	// Backend toggle. Use for validation.
	soldFilter: 'soldFilter',
	testEnv: 'testEnv',
	vehicleStateExplorer: 'vehicleStateExplorer',
	webVitalsLogging: 'webVitalsLogging',
});

export const TEMPORARY_FEATURES = Object.freeze({
	accountReporting: 'accountReporting',
	accountSettings: 'accountSettings',
	additionalMileage: 'additionalMileage',
	auctionResultsModal: 'auctionResultsModal',
	bidCountsSort: 'bidCountsSort',

	// Backend toggle. Use to switch all bidding related endpoints to use prepareBiddingClientWithAuth
	biddingMigrationBidsEndpoint: 'biddingMigrationBidsEndpoint',

	biddingMigrationCancelBidEndpoint: 'biddingMigrationCancelBidEndpoint',

	// Temporary feature flag to be removed after problem with NODE_ENV being passed to cypress is resolved
	disablePromoBanner: 'disablePromoBanner',

	// Dealer account base flag, managed from BE.
	documentCaptureV2: 'documentCaptureV2',

	enhancedServiceHistory: 'enhancedServiceHistory',
	estimatedDeliveryCosts: 'estimatedDeliveryCosts',
	expandedPreviousOwners: 'expandedPreviousOwners',
	inspectionAppUrl: 'inspectionAppUrl',

	// FF for Generic KYC features. Initially within account-settings
	kyc: 'kyc',

	// Temporary feature flag for the new section Mechanical & Electronics Issues
	mechanicalAndElectronicsIssuesSection: 'mechanicalAndElectronicsIssuesSection',

	modifySmartVehicleSuggestionsBrazeMessage: 'modifySmartVehicleSuggestionsBrazeMessage',

	// Motorway Move callback functionality
	motorwayMoveCallback: 'motorwayMoveCallback',

	nextGearPaymentOptions: 'nextGearPaymentOptions',
	// Dealer account base flag, managed from BE.
	paymentsFeeCollection: 'paymentsFeeCollection',
	// Dealer account base flag, managed from BE.
	paymentsHideSellerBankDetails: 'paymentsHideSellerBankDetails',
	// Dealer account base flag, managed from BE.
	paymentsKYC: 'paymentsKYC',
	paymentsMVP: 'paymentsMVP',
	paymentsOTP: 'paymentsOTP',
	paymentsOTPPhone: 'paymentsOTPPhone',
	penTestingBidding: 'penTestingBidding',
	// Backend toggle. Use for validation.
	searchService: 'searchService',
	sellerTypeFilter: 'sellerTypeFilter',
	similarVehiclesMakeModel: 'similarVehiclesMakeModel',
	similarVehiclesV2: 'similarVehiclesV2',
	socketErrorToast: 'socketErrorToast',
	surfacingBidInformation: 'surfacingBidInformation',
	tradeDealerInfo: 'tradeDealerInfo',
	tradeExperience: 'tradeExperience',
	tradeNavigation: 'tradeNavigation',
	tradeVehicleBadge: 'tradeVehicleBadge',
	tradeVehicleBadgeAccount: 'tradeVehicleBadgeAccount',
	transportExperience: 'transportExperience',
	vehicleDetailsRedesign: 'vehicleDetailsRedesign',
	walletWithdrawal: 'withdraw_funds',
});

export const FEATURES = Object.freeze({ ...PERMANENT_FEATURES, ...TEMPORARY_FEATURES });

export const FEATURES_ARRAY = Object.keys(FEATURES);

export const FEATURES_ENABLED_COOKIE_NAME = 'mwDisplayFeaturesManager';

export const MIRAGE_SCENARIO = 'mirageScenario';

export const ALL_FEATURES_RELATED_COOKIES = [].concat(FEATURES_ENABLED_COOKIE_NAME, FEATURES_ARRAY);

export const COMMA_SEPARATED_IDS_EXPRESSION = /^([1-9]\d*)(,\d+)*$/g;

export const COMMA_SEPARATED_NEGATIVE_IDS_EXPRESSION = /^(![1-9]\d*)(,!\d+)*$/g;

export const FEATURE_SETS = [
	{
		features: PERMANENT_FEATURES,
		title: 'Permanent features',
	},
	{
		features: TEMPORARY_FEATURES,
		title: 'Temporary features',
	},
];
